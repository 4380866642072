// Config values
$platform-name: "pohwer-rebrand";
$assets-path: "../assets/";

// Colours
$_dark-blue: #1d1c3b;
$_light-blue: #32b7bb;
$_red: #ea5a25;
$_yellow: #fbbb0e;
$_green: #7dbd61;

$_light-pink: #fde9e8;
$_chocolate: #2d0f0b;
$_dark-green: #074343;

$brand-primary: $_dark-blue;
$brand-primary-text-contrast-override: $_light-pink;
$brand-secondary: $_light-blue;
$brand-secondary-text-contrast-override: $_dark-blue;

$donate-colour: $_yellow;

// Create greyscale
$black: #000;
$grey-dark: #292b2c;
$grey: #464a4c;
$grey-light: #636c72;
$grey-lighter: #eceeef;
$grey-lightest: #F0F0F0;
$white: #fff;

// Layout
$container-max-width: 1280px;
$gap-width: 20px;
$site-bleed: 20px;

$border-radius: 10px;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $_dark-blue;
$profile-pics-enabled: false;
$border-width: 0;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.2s;
$transition-duration-slow: 0.2s;
$transition-curve: ease-in-out;

// Logo
$logo: "logo.svg";
$logo-width: 310px;
$logo-height: 110px;
$logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile: $logo;
$logo-mobile-retina: $logo-mobile;
$logo-mobile-width: $logo-width * 0.65;
$logo-mobile-height: $logo-height * 0.65;

// Typography
$font-family-base: "Open Sans", sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: 'Quicksand', sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $_dark-green;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $_yellow;
$btn-text-colour: $_chocolate;
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 1.25em;
$btn-padding-y: 0.625em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-font-size: inherit;
$btn-text-transform: none;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: $_chocolate;

// // Social icons
$social-icons-background-colour: transparent;
$social-icons-colour: $_dark-blue;
$social-icons-hover-colour: $_yellow;
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.35rem;
$social-icons-gap: 0;
$social-icons-border-radius: 0;
$social-icons-box-shadow: none;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $_dark-blue;
$social-icons-header-hover-colour: $_yellow;

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $_dark-blue;
$social-icons-footer-hover-colour: $_yellow;

// Share this page
$share-enabled: true;
$share-text-align: left; // left, center, right
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: 10px;
$card-border: 1px solid $grey-lighter;
$card-box-shadow: none;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: transparent;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer * 1.5;
$card-details-background-colour: $white;
$card-details-background-gradient: none;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $_dark-green;
$card-heading-font-size: $font-size-h3;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: $body-colour;
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.05);
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: $body-colour;
$card-footer-padding: .5rem $card-details-padding;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above; // above, below

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom;
$card-text-overlay-details-position-x: left;

// Card text over
$card-text-over-image-vertical-text-position: center;
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-grow-image: true;
$card-side-by-side-vertical-text-position: top;
$card-side-by-side-breakpoint: map-get($breakpoints, sm);

// Card hover state
$card-hover-border-colour: $grey-lighter;
$card-hover-box-shadow: 0 0 10px rgba($black, 0.15);
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.05;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient;
$card-hover-heading-colour: $_dark-green;
$card-hover-summary-colour: text-contrast($card-hover-details-background-colour);

// Event cards
$event-card-floating-date: true;
$event-card-floating-date-background-colour: $card-details-background-colour;
$event-card-floating-date-colour: text-contrast(
  $event-card-floating-date-background-colour
);
$event-card-floating-date-border-radius: $border-radius;
$event-card-floating-date-margin-y: $spacer;
$event-card-floating-date-margin-x: $spacer;

// Menu admin
$menu-admin-enabled: true;
// $menu-admin-max-width: 100%; // $container-max-width
// $menu-admin-background-colour: $grey-lighter;
// $menu-admin-contents-max-width: $container-max-width; // 100%
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: right; // left, center, right
// $menu-admin-link-colour: $link-colour;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: true;
// $menu-admin-manager-link-enabled: true;
// $menu-admin-my-details-link-enabled: true;
// $menu-admin-logout-link-enabled: true;
// $menu-admin-to-nav-breakpoint: 0px;

// Header
$header-background-colour: $white;
$header-background-gradient: none;
$header-box-shadow: none;

$header-absolute: false; // true if you want the header to appear transparently over banner images
$header-absolute-background: linear-gradient(
  to bottom,
  rgba($black, 0.5),
  rgba($black, 0)
); // Colour or gradient
$header-absolute-breakpoint: map-get($breakpoints, sm);

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: $spacer;
$header-content-margin-bottom: $spacer;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: true;
$tagline-font-family: $headings-font-family;
$tagline-font-weight: $font-weight-bold;
$tagline-font-size: $font-size-h3;
$tagline-colour: $headings-colour;
$tagline-above-cta-breakpoint: 8000px;
$tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: $_light-blue;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 5px;
// $cta-padding-right-at-nav-breakpoint: 6.5em; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint
$cta-btn-breakpoint: 650px;
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;
$header-search-margin-right: 10px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 180px;
$header-search-input-height: 40px;
$header-search-input-margin-right: -40px;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: $input-placeholder-colour;
$header-search-input-border-radius: $border-radius;
$header-search-input-border-colour: $border-colour;
$header-search-input-border-width: 1px 1px 1px 1px; // t-r-b-l
$header-search-input-background-colour: $white;
$header-search-input-colour: text-contrast($header-search-input-background-colour);
$header-search-input-focus-background-colour: $white;
$header-search-input-focus-colour: text-contrast(
  $header-search-input-focus-background-colour
);

// Search - button
$header-search-button-width: 40px;
$header-search-button-height: 40px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: $grey-light;
$header-search-button-border-radius: 0;

// Search when in nav
$nav-search-enabled: $header-search-enabled;
$nav-search-input-margin-right: 1rem;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
$nav-search-input-border-colour: $header-search-input-border-colour;
$nav-search-input-border-width: 0;
$nav-search-input-background-colour: $header-search-input-background-colour;
$nav-search-button-background-colour: transparent;
$nav-search-button-icon-colour: $_light-pink;

// Social icons
$header-social-icons-margin-right: 0;
$header-social-icons-margin-bottom: 10px;
$header-social-icons-above-cta-buttons: true;
$header-social-icons-breakpoint: map-get($breakpoints, lg);

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $white;
$header-sticky-box-shadow: 0 0 10px rgba($black, 0.15);
$header-sticky-logo: $logo;
$header-sticky-logo-width: $logo-width * 0.65;
$header-sticky-logo-height: $logo-height * 0.65;
$header-sticky-menu-admin-enabled: true;
$header-sticky-search-enabled: true;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: true;
$header-sticky-header-content-margin-top: $header-content-margin-top * 0.5;
$header-sticky-header-content-margin-bottom: $header-content-margin-bottom * 0.5;
$header-sticky-nav-margin-bottom: 0;

// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $_dark-blue;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-colour: $_light-pink;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $_light-pink;
$nav-top-level-item-hover-background-colour: rgba($white, 0.08);
$nav-top-level-chevrons-enabled: false;
$nav-top-level-chevrons-colour: $_light-pink;
$nav-top-level-first-item-home-icon: true;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $_light-blue;
$nav-submenu-box-shadow: none;
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $_chocolate;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-bold;
$nav-submenu-item-hover-colour: $_chocolate;
$nav-submenu-item-hover-background-colour: rgba($black, 0.1);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $_chocolate;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $body-colour;
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: map-get($breakpoints, sm);

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $brand-primary;
$nav-normal-mobile-top-level-item-colour: $_light-pink;
$nav-normal-mobile-submenu-background-colour: rgba($white, 0.08);
$nav-normal-mobile-submenu-item-colour: $_light-pink;
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $_light-pink;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: transparent;
$carousel-contents-max-width: 100%;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: top;
$carousel-details-position-x: left;
$carousel-details-background-colour: rgba($_yellow, 0.9);
$carousel-details-box-shadow: none;
$carousel-details-max-width: 500px;
$carousel-details-min-height: 100%;
// $carousel-details-padding: $spacer*6 $spacer*2 $spacer*2 $spacer*2;
$carousel-details-padding: $spacer*4 $spacer*2 $spacer*2 $spacer*2;
$carousel-details-margin-y: 0px;
$carousel-details-margin-x: 0px;
$carousel-details-border-radius: 0;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $_chocolate;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: true;
$carousel-summary-colour: $_chocolate;
$carousel-summary-font-size: inherit;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $white;
$carousel-read-more-border-colour: transparent;
$carousel-read-more-colour: $body-colour;
$carousel-read-more-font-size: $carousel-summary-font-size;

// Carousel controls
// $carousel-controls-enabled: true;
$carousel-controls-enabled: true;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 5px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 0;
$carousel-controls-button-box-shadow: none;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-margin-y: $spacer;
$carousel-controls-margin-x: $spacer;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: 1.25em;
$carousel-controls-next-icon: "\f054";
$carousel-controls-prev-icon: "\f053";

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
$carousel-buttons-type: dots;

// // Carousel buttons dots
// $carousel-dots-position-y: bottom; // top, bottom
// $carousel-dots-position-x: right;
// $carousel-dots-padding: $spacer * 2;
// $carousel-dots-colour: $white;
// $carousel-dots-opacity: 0.5;
// $carousel-dots-active-colour: $_light-blue;
//
// // Carousel video play button
// $carousel-video-btn-position-y: 50%;
// $carousel-video-btn-position-x: 50%;

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
// $carousel-scroll-btn-size: $carousel-controls-button-size;
// $carousel-scroll-btn-background-colour: $btn-background-colour;
// $carousel-scroll-btn-border-radius: $border-radius;
// $carousel-scroll-btn-box-shadow: $box-shadow;
// $carousel-scroll-btn-icon-colour: $btn-text-colour;
// $carousel-scroll-btn-icon-font-size: 1em;
// // $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
// $carousel-scroll-btn-margin-y: 15%; // Can use a negative value to get it to peek over the edge
// $carousel-scroll-btn-animation-prefab: 0; // Pick a number from 0 (off) to 3!
//
// // Carousel background YouTube video
// $carousel-yt-background-enabled: false;
// $carousel-yt-background-size: "cover"; // 'cover' or 'contain'
// $carousel-yt-cover-padding-bottom: 56.25%;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $_yellow;
$carousel-mobile-heading-colour: $_chocolate;
$carousel-mobile-summary-colour: $_chocolate;

// Banner min-height
$banner-min-height: 200px;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $white;
$home-intro-background-gradient: none;
$home-intro-box-shadow: $box-shadow;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 2;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: justify;
$home-intro-text-transform: $headings-text-transform;
$home-intro-font-family: $headings-font-family; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-h5;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: $border-radius;

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $grey-lightest;
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 3;
// $home-features-cards-entry-animation-prefab: 0;
// $home-features-cards-entry-animation-stagger: true;
// $home-features-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 5;
$impact-stats-background-colour: $white;
$impact-stats-background-image: null;
$impact-stats-background-image-opacity: 0.5;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: $border-radius;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: center; // left, center, right
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get(
  $breakpoints,
  md
); // When do you want to break the overall layout from row to column?

$impact-stats-overall-container-direction: column; // column, row for the .homeImpactWrapper
$impact-stats-direction: row; // Do you want the stats in a row or column?
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true; //true or false
$impact-stats-heading-font-size: $font-size-h2;
$impact-stats-heading-text-align: $impact-stats-text-align; // left, center, right
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer * 3;

// Individual stat
$impact-stat-min-width: 180px; // Use this to control how many you get in a row
$impact-stat-direction: column; // column, row
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour; // Style border here
$impact-stat-border-width: 0 0 0 0; // Set border widths (t-r-b-l) here

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 3.5em;

// Summary
$impact-stats-summary-colour: inherit;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: 0;

//
// Home feeds
//

$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 5;
$home-feeds-padding-bottom: $spacer * 5;
$home-feeds-cards-entry-animation-prefab: 0;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h2;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: true;

// Twitter feed
$home-feed-twitter-enabled: false;

// Footer
$footer-boxes-count: 3;
$footer-prefab: 1;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 3;
$footer-background-colour: $_light-blue;
$footer-background-gradient: none;
$footer-colour: $_chocolate;
$footer-link-colour: $_chocolate;
$footer-link-decoration: none;
//$footer-link-hover-colour: lighten($_chocolate, 10%);
$footer-link-hover-decoration: underline;
$footer-text-align: left;
$footer-font-size: inherit;
$footer-headings-font-size: $font-size-h2;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $_yellow;
$newsletter-background-gradient: none;
$newsletter-colour: $_chocolate;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h2;
$newsletter-heading-text-align: center;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 2;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px;
$newsletter-button-background-colour: $_red;
$newsletter-button-colour: $_chocolate;
$newsletter-button-border-colour: $_red;
$newsletter-use-placeholders: true;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 3;
$newsletter-hero-contents-max-width: $footer-contents-max-width;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: $profile-pics-enabled;

// Posts
$post-content-max-width: $container-max-width;
$post-body-background-colour: transparent;

// Header text
$header-text-margin-top: $spacer * 3;
$header-text-margin-bottom: $spacer * 1.5;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;
$page-title-md-font-size: $page-title-font-size * .8;
$page-title-sm-font-size: $page-title-font-size * .6;

// Page title over banner
$page-title-over-banner-enabled: false;
$page-title-over-banner-breakpoint: map-get($breakpoints, md);
$page-title-over-banner-background-colour: $brand-primary;
$page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour);
$page-title-over-banner-breadcrumb-enabled: true;

// Sidebar
$sidebar-enabled: true;
$sidebar-layout: adjacent;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 5;
$sidebar-background-colour: $_yellow;
$sidebar-padding: $spacer;

// Associated lists
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: true;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 4;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-summary-enabled: false;
$associated-item-border: false;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-body-background-colour: transparent;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: true;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: true;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align;

// Blockquote
$blockquote-max-width: $post-content-max-width;
$blockquote-background-colour: $brand-primary;
$blockquote-background-gradient: none;
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: $spacer * 2;
$blockquote-padding: $spacer * 2;
$blockquote-text-align: center;
$blockquote-font-size: 1.5em;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: text-contrast($blockquote-background-colour);
$blockquote-box-shadow: $box-shadow;

// Fundraising
$fundraising-enabled: true;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: $profile-pics-enabled;

// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: false;

// Donation amounts
$donation-amount-min-width: $card-min-width;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: simple; // simple, columns or rows
$quick-giving-background-colour: $context-container-background-colour;
$quick-giving-background-gradient: none; // none or CSS gradient
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.65;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $body-colour;
$quick-giving-donation-amount-border-width: 1px;
$quick-giving-donation-amount-border-colour: $_yellow;
$quick-giving-donation-amount-selected-background-colour: $_yellow;
$quick-giving-donation-amount-selected-figure-colour: $white;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: 100%;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 5;
$home-quick-giving-background-colour: $_light-blue;
$home-quick-giving-background-gradient: $quick-giving-background-gradient;
$home-quick-giving-background-image: null;
$home-quick-giving-background-image-opacity: 0.5;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $body-colour;
// $home-quick-giving-donation-amount-border-width: $btn-border-width;
$home-quick-giving-donation-amount-border-colour: $_yellow;
$home-quick-giving-donation-amount-selected-background-colour: $_yellow;
$home-quick-giving-donation-amount-selected-figure-colour: $_chocolate;
$home-quick-giving-donate-btn-background-colour: $btn-background-colour;
$home-quick-giving-donate-btn-colour: $btn-text-colour;
$home-quick-giving-donate-btn-border-colour: $btn-border-colour;
$home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
$home-quick-giving-colour:$_chocolate;
$home-quick-giving-heading-colour: $_chocolate;
$home-quick-giving-heading-font-size: $font-size-h2;

// Adjacent homepage quick giving
$home-quick-giving-type-adjacent-image-side: left;

// Overlay homepage quick giving
$home-quick-giving-type-overlay-items-side: left;
$home-quick-giving-type-overlay-gradient: linear-gradient(
  to left,
  rgba(0, 0, 0, 0) 40%,
  rgba(0, 0, 0, 0.75) 100%
);

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;

// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: true;

// Subsite
//

$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: false;
$subsite-hide-header-search: false;
$subsite-hide-main-cta: false;
$subsite-content-max-width: $container-max-width;
$subsite-title-font-size: $page-title-font-size;

// Subsite logo
$subsite-logo: "logo.svg"; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage
$subsite-logo-retina: $subsite-logo;
$subsite-logo-width: 120px;
$subsite-logo-height: 120px;
$subsite-logo-margin: $header-content-margin-top 0 0;

// Subsite nav
$subsite-nav-breakpoint: $nav-breakpoint;
$subsite-nav-max-width: $nav-normal-max-width;
$subsite-nav-margin-top: $spacer;
$subsite-nav-margin-bottom: $spacer;
$subsite-nav-background-colour: $nav-background-colour;
$subsite-nav-contents-max-width: $container-max-width;
$subsite-nav-align-items: $nav-normal-align-items; // left, center, right
$subsite-nav-top-level-item-padding: $nav-top-level-item-padding;
$subsite-nav-top-level-item-colour: $nav-top-level-item-colour;
$subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family;
$subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size;
$subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight;
$subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour;
$subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
$subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour;

$subsite-nav-submenu-width: $nav-submenu-width;
$subsite-nav-submenu-background-colour: $nav-submenu-background-colour;
$subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow;
$subsite-nav-submenu-border-radius: $nav-submenu-border-radius;
$subsite-nav-submenu-item-padding: $nav-submenu-item-padding;
$subsite-nav-submenu-item-colour: $nav-submenu-item-colour;
$subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family;
$subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size;
$subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight;
$subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour;
$subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour;

// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: rgba($black,  0.85);
$cookie-consent-colour: $white;
$cookie-consent-btn-background-colour: $_red;
$cookie-consent-btn-text-colour: $_chocolate;

// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: true;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: true;

// Product-price
// https://dev.azure.com/access-devops/Access%20Charity%20Websites%20-%20Design/_workitems/edit/377035
// Pohwer - Shop price colour change to Navy Blue
$product-price-colour: #1D1C3B;