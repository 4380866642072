// Pseudo element - fix
body.homepage {
  overflow-x: hidden;
  overflow-y: visible;
}

// Header CTA buttons
.mainCallToActionButtons .cta-button:not(.reciteme):not(.donate) {
  background-color: $_yellow;
  color: $_chocolate;

  &:hover {
    background-color: darken($_yellow, 10%);
  }
}

// Header Layout - search bar
@media (min-width: 1025px) {
  .header-search {
    margin-top: auto !important;
    margin-bottom: calc(0.5rem + 2px) !important;

    // Fixing the search bar in IE11, please don't judge
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 45px;
    }
  }
}

// Mobile Header
@media (max-width: map-get($breakpoints, sm)) {
  a.mainLogo { margin-bottom: 10px; }
  .pageHeader .headerContent { margin-bottom: 0; }
  .headerContent .cta-button.callUs { min-width: 77.31px; }
}

@media (min-width: map-get($breakpoints, sm) + 1px) {
  .headerContent .cta-button.callUs {
    display: none;
  }
}


// Tagline - Setup
ul.socialIcons {
  position: relative;
}

ul.socialIcons li.phone {
  font-family: $headings-font-family;
  position: absolute;
  right: calc(100% + 10px);
  top: 0;
  width: 250px;
  line-height: 2;
  font-size: $font-size-h5;
  font-weight: $font-weight-bold;
  text-align: right;

  a {
    font-size: 1em;
    width: auto;
    height: auto;
    display: inline-block;
    color: $_red;

    &:hover {
      color: darken($_red, 10%);
    }
  }

  @media (max-width: 1024px) {
    position: relative;
    top: auto;
    right: auto;
    font-size: 0;
    width: auto;
    a {
      width: 40px;
      height: 40px;
      font-size: 0;
      display: flex;

      &:before {
        content: '\f095';
      }
    }
  }
}

// Nav - Burger button
.menuMainAlt:hover,
.menuMainAlt.active {
  background-color: transparent;
  color: $body-colour;
}

@media (max-width: 576px) {

  .menuMainAlt,
  .menuMainAlt.active {
    background-color: $_dark-blue;
    color: $_light-pink;

    &:hover {
      color: $_light-pink;
      background-color: $_dark-blue;
    }
  }
}


// Carousel Details - Pseudo
.carouselSlideDetail {
  position: relative;

  >* {
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 26vw;
    height: 19vw;
    background-image: url($assets-path + 'pattern-cubic-blue.png');
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
    transform: translate(-12.5%, 37.5%);
    opacity: 0.85;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 100%;
    height: 100%;
    width: 100%;
    background-image: url($assets-path + 'carousel-diagonal-yellow.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover 100%;
    opacity: 0.9;
    z-index: 1;
  }

  @media (max-width: 1024px) {

    &:before,
    &:after {
      display: none;
    }
  }
}


// Carousel - Read more Button
.carouselSlideReadMore {
  font-weight: 700;

  &:hover {
    color: $_light-pink;
    background-color: $_dark-blue;
  }
}


// HomeIntro - Pseudo
.homeIntro {
  z-index: 1;
}

.homeIntroWrapper:before {
  content: '';
  display: block;
  position: absolute;
  width: 105%;
  height: 100px;
  top: 0;
  left: 0;
  background-color: $white;
  transform: rotate(1.2deg);
  transform-origin: top right;
  z-index: 0;

  @media (max-width: 1024px) {
    display: none;
  }
}

// HomeIntro - Button
.homeIntro [class*="utton"] a {
  text-decoration: none !important;
}


// Homepage Stats - Button
.homeImpactWrapper .cta-button {
  margin: 0 auto;
  max-width: 126px;
}


// Homepage Stats - Pseudo
.homeImpactWrapper {
  z-index: 1;
}

.homefeaturecategory-homeboximpactstats {
  position: relative;
  z-index: 2;
}

.homefeaturecategory-homeboximpactstats:after {
  content: '';
  display: block;
  position: absolute;
  width: 105%;
  height: 100px;
  bottom: 0;
  right: 0;
  background-color: $white;
  transform: rotate(1.2deg);
  transform-origin: bottom left;
}

// Homepage Quick Giving - Pseudo
.homefeaturecategory-homeboxquickgiving {
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 20vw;
    height: 20vw;
    bottom: 0;
    right: 0;
    transform: translate(-45%, 15%);
    background-image: url($assets-path + 'pattern-lines-yellow.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    z-index: -1;
    min-width: 220px;
    min-height: 220px;

    @media (max-width: 900px) {
      display: none;
    }
  }
}


// Homepage Quick Giving - Layout
.homepage-quickgiving--container {
  z-index: 1;
  flex-direction: column;
}

.homepage-quickgiving--copy {
  text-align: center;
  margin-bottom: 20px;
  max-width: 900px;
}


// Newsletter - Psuedo
.footerBox[class*="ewsletter"] {
  position: relative;
  z-index: 1;

  >p {
    max-width: $container-max-width;
    padding: 0 $site-bleed;
    margin-left: auto;
    margin-right: auto;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 105%;
    height: 100px;
    top: 0;
    right: 0;
    background-color: $newsletter-background-colour;
    transform: rotate(-1.2deg);
    transform-origin: top left;
    z-index: -1;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url($assets-path + 'pattern-waves-orange.png');
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;
    top: calc(-4vw - 10px);
    left: calc(3vw - 10px);
    width: 13vw;
    height: 10vw;
    min-width: 130px;
    min-height: 110px;
    z-index: -1;
  }
}


// Footer - Logos
.footerBox.FooterLogosAndLegal {
  margin: 1rem 0 0 0;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  img {
    margin: 20px;
    max-width: 120px;
    max-height: 120px;
    object-fit: contain;
  }

  p {
    text-align: center;
    color: $grey-light;
    margin-top: 1rem;
    font-size: 0.85em;
  }
}


// Breadcrumb - colour
.breadcrumbWrapper a {
  color: $_dark-green;
  font-weight: $font-weight-bold;
}

// Social Icons - hover
ul.socialIcons a:hover {
  text-decoration: none !important;
}

// Sidebar - colour
.post .contentBlock .postAside>* {
  border-radius: $border-radius;
}

.postAside .associated>h2 {
  color: $_chocolate;
}

.postAside .associated ul li {
  border: 0 !important;
}

.postAside .associated ul li a {
  color: $_chocolate;
}

.postAside .associated ul li a:hover {
  color: darken($_chocolate, 10%);
}


// Subsite - order + layout
body.subsite .contentContainer {
  .headerTextSubsite {
    order: 2 !important;
  }

  .headerWrapperSubsite {
    order: 1 !important;
  }

  nav.menuSub {
    display: none;
  }

  // Terrible ways, I know. But can't do otherwise.
}

// Subsite - Titles - no logos
.headerTextSubsite .subsiteTitle {
  font-size: $font-size-h1;
  text-indent: 0;
  background-image: none;
  width: auto;
  height: auto;
  margin-top: 3rem;
  color: $headings-colour;
}


// Inner page client styles


.cta-button.light-blue {
  color: $_chocolate;
  background-color: $_light-blue;

  &:hover {
    background-color: darken($_light-blue, 10%);
  }
}

.cta-button.yellow {
  color: $_chocolate;
  background-color: $_yellow;

  &:hover {
    background-color: darken($_yellow, 10%);
  }
}

.cta.button.green {
  color: $_chocolate;
  background-color: $_green;

  &:hover {
    background-color: darken($_green, 10%);
  }
}

.cta-button.dark-blue {
  color: $_light-pink;
  background-color: #1d1c3b;

  &:hover {
    background-color: #373655;
  }
}


blockquote.light-blue {
  color: $_chocolate;
  background-color: $_light-blue;
}

blockquote.yellow {
  color: $_chocolate;
  background-color: $_yellow;
}

blockquote.green {
  color: $_chocolate;
  background-color: $_green;
}

blockquote.dark-blue {
  color: $_light-pink;
  background-color: #1d1c3b;
}

// Bespoke client styles -  inherited from old design, yaayy!
// This bit is scary!
.promoBox {
  display: flex;
  flex-wrap: wrap;
  float: left;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  margin: 0 15px 20px 15px;
  background-color: #fde9e8;
  text-align: center;
  border-radius: 10px;

  p,
  h2,
  h3 {
    text-align: center !important;
    width: 100%;
  }

  a {
    color: #1d1c3b;
  }

  * {
    justify-self: center;
  }
}

/* Three Boxes modified 18-07-2016 AdamW */
.threeBoxContainer {
  display: flex;
  flex-wrap: wrap;
}
.threeBox {
  width: calc(calc(100% / 3) - 40px);
  &:nth-of-type(3n + 1) {
    clear: left;
  }
}

/* Two Boxes */
.twoBox {
  width: calc(50% - 40px);
}

@media screen and (max-width: 768px) {
  .promoBox {
    float: none;
    width: 100%;
    margin: 0 0 20px 0;
  }
}


/* Also, fix .study_box on mobile */
@media screen and (max-width: 768px) {
  .study_box {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .study_box img {
    float: none;
  }
}

.location_left {
  width: calc(50% - 30px);
  float: left;
  margin: 15px;

  &:hover a img {
    opacity: 0.8;
  }
}

.location_right {
  width: 45%;
  float: right;
  margin: 15px;

  &:hover a img {
    opacity: 0.8;
  }
}

.casestudy_left {
  width: 45%;
  float: left;
  margin: 15px;
}

.twitter_right {
  width: 45%;
  float: right;
  margin: 15px;
}


// Inner page nightmare.
.subsiteBody p img {
  vertical-align: middle;
}

.pageContent a {
  vertical-align: middle;
}


// Title - Add underline
h1.title,
h1.subsiteTitle {
  margin-bottom: 1rem;
  @include heading-underline($colour: $brand-secondary,
    $width: 3em,
    $height: 3px);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .carouselSlideDetail::after {
    display: none;
  }
}

.carouselControls li {
  background-color: rgba($_dark-blue, 0.5);
  &:hover {
    background-color: rgba($_dark-blue, 0.7);
  }
}

.homeFeed {
  padding: 2rem 0;
}

@media (min-width: map-get($breakpoints, lg) + 1) {
  li.feedItem {
    max-width: calc(100% / 3);
  }
}

// IM-712
.subsiteTitle {
  cursor: auto !important;
}

// CR-133
#menuAdmin li:not(.menuAdminBasket) {
  display: none;
}

// Add scrollbar to submenus https://raisingit.atlassian.net/browse/DES-5616
ul.level2 {
  overflow-x: scroll;
}

@media (max-width: 1024px) {
  .menuMain .subMenu:not(.active) {
    max-height: 0 !important;
  }
}

// SF 60026901 - color updates

a.button.callToActionBtn.addToBasket{
background-color: $_light-blue;
color: $_chocolate;

&:hover {
  background-color: darken($_light-blue, 10%);
}
}

a.cta-button{
  background-color: $_light-blue;
  color: $_chocolate;
  &:hover {
    background-color: darken($_light-blue, 10%);
  }
}

  button#groupSignUpBtn.groupSignUpBtn{
    background-color: $_light-blue;
    color: $_chocolate;
    
    &:hover {
      background-color: darken($_light-blue, 10%);
    }
  }

  a.button.loadMore{
    background-color: $_light-blue;
    color: $_chocolate;
    
    &:hover {
      background-color: darken($_light-blue, 10%);
    }
  }

/* DES-5997 */
#menuAdmin li.menuAdminBasket {
  a {
    span {
      color: $_dark-green;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &:before {
    color: $_dark-green;
  }
}
.postContent, 
.listingIntro, 
.faqQuestion .accordianHeaderText {
  a {
    color: $_dark-green;
    text-decoration: underline;
    &:hover {
      color: $_dark-blue;
    }
  }
 
  a.cta-button, .cta-button a { /* this covers the various ways cta-buttons are inserted via content editor */
    text-decoration: none;
    color: inherit;
  }
}
.postContent .shareList a {
  text-decoration: none;
}
.postContent .shareList a::before {
  color: $_dark-blue;
}
.subsiteBody{
  a{
    color: $_dark-green;
    &:hover {
      text-decoration: underline;
    }
  }
}

.phone-color{
  color: $_dark-green;
   &:hover{
    color: $_dark-green;
    text-decoration: underline;
   }
}

/* Print button added on sharelist CR-478 */
.shareList .printButton:before {
  content: "\f02f";
}

.listedSearchResult {
  a{
    color: $_dark-green;
    &:hover{
     color: $_dark-green;
     text-decoration: underline;
    }
  }
}

// 1305585 SA - Footer Mailchimp newsletter
.footerBox[class*='ewsletter'] #mc-embedded-subscribe-form {
  position: relative;
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $site-bleed;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  text-align: center;

  .indicates-required { 
      display: none;
  }
  .mc-field-group {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: $card-min-width;
      padding: 0 #{$card-gap-width / 4} #{$card-gap-width / 4} #{$card-gap-width / 4};
      margin-top: $spacer;
  }
  .clear:last-of-type {
      padding: #{$card-gap-width / 4};
      margin-top: $spacer;
  }
  .cta-button {
      height: $newsletter-button-height;
      background-color: $_light-blue;
      color: $newsletter-button-colour;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      padding-bottom: 0;
      &:hover {
          background-color: darken($_light-blue, 10%);
      }
  }
  // Mobile layout
  @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
      justify-content: center;
      align-self: center;
      * { width: 100%; }
  }
}