@if $nav-top-level-first-item-home-icon == true {
  @if $warn == true {
    @warn("Please add '/' as the first nav item - Fresco won't do this for you");
  }

  .menuMain .topLevel > li.level1:first-child > a {
    @media (min-width: $nav-breakpoint + 1px) {
      font-size: 0; // Hide the label
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;
    }
    align-items: center;

    &:before {
      content: '\f015';
      font-family: $icon-font-family;
      display: block;
      font-size: $nav-top-level-first-item-home-icon-font-size;
      font-weight: $font-weight-normal;
      @media (max-width: $nav-breakpoint) {
        margin-right: 10px;
      }
    }

    &:after {
      display: none; // Hide any chevrons that might be there
    }
  }
}